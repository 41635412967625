<template>
  <div>
    <div class="container">
      <div class="row d-md-flex justify-content-between mt-3 mb-5">
        <div class="col-md-12">
          <h2 class="font-weight-700">Online Giving Setup</h2>
        </div>
      </div>

      <div class="grey-rounded-border">
          <div class="col-md-12">
              <div class="row">
                  <div class="col-md-12 py-2">
                      <h4 class="mb-0">Setup Your Online Giving Page</h4>
                      <p class="mb-0">The Donation Link</p>
                  </div>
              </div>

              <div class="row">
                  <div class="col-md-12 px-0">
                      <hr class="hr my-0">
                  </div>
              </div>

              <div class="row my-2 mt-4">
                  <div class="col-md-3 text-right">
                      <label for="">Bank</label>
                  </div>
                  <div class="col-md-5">
                      <input type="text" class="form-control">
                  </div>
                  <div class="col-md-4">

                  </div>
              </div>

              <div class="row my-2">
                  <div class="col-md-3 text-right">
                      <label for="">Account Number</label>
                  </div>
                  <div class="col-md-5">
                      <input type="text" class="form-control">
                  </div>
                  <div class="col-md-4">

                  </div>
              </div>

              <div class="row my-2 mb-5">
                  <div class="col-md-3 text-right">
                      <label for="">Account Name</label>
                  </div>
                  <div class="col-md-5">
                      <input type="text" class="form-control">
                      <p>This will automatically come up, kindly confirm before save</p>
                  </div>
                  <div class="col-md-4">

                  </div>
              </div>

              <div class="row my-2 mb-5">
                  <div class="col-md-3 text-right">
                  </div>
                  <div class="col-md-6 d-flex justify-content-center">
                      <button class="primary-btn px-2 outline-none">Save and get payment link</button>
                  </div>
                  <div class="col-md-3">

                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>